import moment, { Moment as MomentType } from 'moment-timezone';
import { TFunction } from 'i18next';

import { ForecastValuesResponseModel } from '@swagger-http';

import { store } from '@src/index';
import { IS_NOT_DEV, AGGREGATION_TIME_FORMAT } from '@tools/constants';
import { PowerUnit, GraphTypes, DateTimeFormat, Scope } from '@tools/enums';
import { BarMap, AxisData, GraphToggle, GraphResolution } from '@tools/types';
import {
    Moment,
    checkForScopes,
    toDateTimeFormat,
    getCSSCustomProp,
    convertPowerValue,
    smartMeterDataUnavailable,
} from '@tools/utils';
import {
    Res,
    SolarCloudStatus,
    InverterProviders,
    HistoricalInterval,
    HistoricalResolution,
    ExtendedHistoricalResolution,
    HistoricalComparisonInterval,
} from '@store/enums';
import {
    UserState,
    EnergyState,
    AggregatedDataModel,
    AggregatedDataValues,
    UnifiedHistoricalData,
    AggregatedDataEnergyState,
} from '@store/types';

export const GRAPH_COLORS = (key: string): string => {
    const colors: Record<string, string> = {
        PV2home: getCSSCustomProp('color-generation'),
        PV2homeToBattery: getCSSCustomProp('color-red-dark'),
        PV2homeToGrid: getCSSCustomProp('color-turquoise-50'),
        PV2homeToHome: getCSSCustomProp('color-bordeaux-25'),
        balance: getCSSCustomProp('color-turquoise-dark'),
        batteryPower: getCSSCustomProp('color-bordeaux'),
        charging: getCSSCustomProp('color-turquoise'),
        discharging: getCSSCustomProp('color-turquoise-50'),
        emobility: getCSSCustomProp('color-turquoise-50'),
        emobilityCharge: getCSSCustomProp('color-turquoise-50'),
        emobilityEnergy: getCSSCustomProp('color-turquoise-50'),
        emobilityPower: getCSSCustomProp('color-turquoise-50'),
        energyConsumptionFromBattery: getCSSCustomProp('color-turquoise'),
        energyConsumptionFromGrid: getCSSCustomProp('color-bordeaux-50'),
        energyConsumptionFromPV: getCSSCustomProp('color-bordeaux-25'),
        exported: getCSSCustomProp('color-turquoise-25'),
        forecast: getCSSCustomProp('color-lightgrey'),
        fromGridElectricity: getCSSCustomProp('color-red'),
        fromGridElectricityCosts: getCSSCustomProp('color-red'),
        fromGridGas: getCSSCustomProp('color-blue'),
        fromGridGasCosts: getCSSCustomProp('color-blue'),
        fromSolarCloud: getCSSCustomProp('color-bordeaux-50'),
        generation: getCSSCustomProp('color-generation'),
        gridPower: getCSSCustomProp('color-turquoise-50'),
        household: getCSSCustomProp('color-consumption'),
        imported: getCSSCustomProp('color-bordeaux-50'),
        inverterPower: getCSSCustomProp('color-generation'),
        nightTariff: getCSSCustomProp('color-lightergrey'),
        powerConsumption: getCSSCustomProp('color-turquoise'),
        powerSelfConsumption: getCSSCustomProp('color-bordeaux-25'),
        prevBalance: getCSSCustomProp('color-middlegrey'),
        prevBatteryPower: getCSSCustomProp('color-middlegrey'),
        prevCharging: getCSSCustomProp('color-middlegrey'),
        prevDay: getCSSCustomProp('color-grey'),
        prevDischarging: getCSSCustomProp('color-grey'),
        prevEmobility: getCSSCustomProp('color-grey'),
        prevEmobilityCharge: getCSSCustomProp('color-middlegrey'),
        prevEmobilityEnergy: getCSSCustomProp('color-middlegrey'),
        prevEmobilityPower: getCSSCustomProp('color-middlegrey'),
        prevEnergyConsumptionFromBattery: getCSSCustomProp('color-grey'),
        prevEnergyConsumptionFromGrid: getCSSCustomProp('color-lightergrey'),
        prevEnergyConsumptionFromPV: getCSSCustomProp('color-middlegrey'),
        prevExported: getCSSCustomProp('color-lightergrey'),
        prevForecast: getCSSCustomProp('color-ultralightgrey'),
        prevFromGridElectricity: getCSSCustomProp('color-red-25'),
        prevFromGridElectricityCosts: getCSSCustomProp('color-red-25'),
        prevFromGridGas: getCSSCustomProp('color-turquoise-50'),
        prevFromGridGasCosts: getCSSCustomProp('color-turquoise-50'),
        prevFromSolarCloud: getCSSCustomProp('color-lightergrey'),
        prevGeneration: getCSSCustomProp('color-grey'),
        prevGridPower: getCSSCustomProp('color-middlegrey'),
        prevHousehold: getCSSCustomProp('color-middlegrey'),
        prevImported: getCSSCustomProp('color-lightergrey'),
        prevInverterPower: getCSSCustomProp('color-middlegrey'),
        prevPV2home: getCSSCustomProp('color-grey'),
        prevPV2homeToBattery: getCSSCustomProp('color-middlegrey'),
        prevPV2homeToGrid: getCSSCustomProp('color-lightergrey'),
        prevPV2homeToHome: getCSSCustomProp('color-middlegrey'),
        prevPowerConsumption: getCSSCustomProp('color-middlegrey'),
        prevPowerSelfConsumption: getCSSCustomProp('color-middlegrey'),
        prevPvbattery: getCSSCustomProp('color-grey'),
        prevSaved: getCSSCustomProp('color-grey'),
        prevSelf: getCSSCustomProp('color-middlegrey'),
        prevSelfsufficiency: getCSSCustomProp('color-middlegrey'),
        prevSolarCloudBalanceDelta: getCSSCustomProp('color-middlegrey'),
        prevState: getCSSCustomProp('color-middlegrey'),
        prevToSolarCloud: getCSSCustomProp('color-lightergrey'),
        pvbattery: getCSSCustomProp('color-generation'),
        saved: getCSSCustomProp('color-turquoise-50'),
        self: getCSSCustomProp('color-turquoise-25'),
        selfsufficiency: getCSSCustomProp('color-bordeaux'),
        solarCloudBalanceDelta: getCSSCustomProp('color-turquoise-dark'),
        state: getCSSCustomProp('color-bordeaux'),
        temperature: getCSSCustomProp('color-turquoise'),
        temperatureLabel: getCSSCustomProp('color-base'),
        temperatureTick: getCSSCustomProp('color-middlegrey'),
        toSolarCloud: getCSSCustomProp('color-turquoise-25'),
        heatingInsightsCool: getCSSCustomProp('color-turquoise'),
        heatingInsightsOptimal: getCSSCustomProp('color-limeyellow'),
        heatingInsightsWarm: getCSSCustomProp('color-red'),
    };

    return colors[key];
};

export const prevBarsMap: BarMap = {
    prevBalance: 'balance',
    prevBatteryPower: 'batteryPower',
    prevCharging: 'charging',
    prevDischarging: 'discharging',
    prevEmobility: 'emobility',
    prevEmobilityEnergy: 'emobilityEnergy',
    prevEmobilityPower: 'emobilityPower',
    prevEnergyConsumptionFromBattery: 'energyConsumptionFromBattery',
    prevEnergyConsumptionFromGrid: 'energyConsumptionFromGrid',
    prevEnergyConsumptionFromPV: 'energyConsumptionFromPV',
    prevExported: 'exported',
    prevForecast: 'forecast',
    prevFromGridElectricity: 'fromGridElectricity',
    prevFromGridElectricityCosts: 'fromGridElectricityCosts',
    prevFromGridGas: 'fromGridGas',
    prevFromGridGasCosts: 'fromGridGasCosts',
    prevFromSolarCloud: 'fromSolarCloud',
    prevGeneration: 'generation',
    prevGridPower: 'gridPower',
    prevHousehold: 'household',
    prevImported: 'imported',
    prevInverterPower: 'inverterPower',
    prevPV2home: 'PV2home',
    prevPV2homeToBattery: 'PV2homeToBattery',
    prevPV2homeToGrid: 'PV2homeToGrid',
    prevPV2homeToHome: 'PV2homeToHome',
    prevPowerConsumption: 'powerConsumption',
    prevPowerSelfConsumption: 'powerSelfConsumption',
    prevPvbattery: 'pvbattery',
    prevSaved: 'saved',
    prevSelf: 'self',
    prevSelfsufficiency: 'selfsufficiency',
    prevState: 'state',
    prevSolarCloudBalanceDelta: 'solarCloudBalanceDelta',
    prevSolarCloudEnabled: 'solarCloudEnabled',
    prevToSolarCloud: 'toSolarCloud',
};

export const getBarSize = (res: HistoricalResolution): number => {
    switch (res) {
        case HistoricalResolution.DAY15MIN:
            return 2;
        case HistoricalResolution.DAY1HOUR:
            return 6;
        case HistoricalResolution.WEEK:
            return 12;
        case HistoricalResolution.MONTH:
            return 5;
        default:
            return 10;
    }
};

export const getOverviewBarSize = (res: HistoricalResolution): number => {
    if (res === HistoricalResolution.DAY15MIN) {
        return 2;
    }

    if (res === HistoricalResolution.DAY1HOUR) {
        return 8;
    }

    if (res === HistoricalResolution.MONTH) {
        return 6;
    }

    if (res === HistoricalResolution.WEEK) {
        return 32;
    }

    return 16;
};

export const getAxisTickData = (
    date: Date,
    res: HistoricalResolution,
): AxisData => {
    const startDate: MomentType = Moment(date)
        .endOf(
            res === HistoricalResolution.WEEK
                ? 'isoWeek'
                : res === HistoricalResolution.MONTH
                ? 'month'
                : res === HistoricalResolution.YEAR
                ? 'year'
                : 'day',
        )
        .subtract(59, 'minutes')
        .subtract(59, 'seconds');

    const tickValues: Date[] = [startDate.toDate()];

    let time: number = 23;
    let step: string = 'hour';
    let format: string = 'HH:mm';

    if (res === HistoricalResolution.WEEK) {
        time = 6;
        step = 'days';
        format = toDateTimeFormat(DateTimeFormat.GRAPH_WEEK);
    }

    if (res === HistoricalResolution.MONTH) {
        time = 30;
        step = 'days';
        format = toDateTimeFormat(DateTimeFormat.GRAPH_MONTH);
    }

    if (res === HistoricalResolution.YEAR) {
        time = 11;
        step = 'months';
        format = toDateTimeFormat(DateTimeFormat.SHORT_MONTH_AND_YEAR);
    }

    for (let i = 1; i <= time; i++) {
        tickValues.push(
            Moment(startDate)
                .subtract(i as any, step)
                .toDate(),
        );
    }

    return {
        tickValues,
        tickFormat: (d: string) =>
            Moment(d)
                .add(res === HistoricalResolution.DAY15MIN ? 15 : 0, 'minutes')
                .format(format),
    };
};

export const isSelected = (
    series: GraphToggle[],
    name: string,
    isPrev: boolean,
): boolean => {
    const isSelectedNotPrevDay: GraphToggle | undefined = series.find(
        // prettier-ignore
        (item: GraphToggle): boolean => (item.name === name || item.alias === name) && item.selected,
    );

    const isSelectedPrevDay: GraphToggle | undefined = series.find(
        (item: GraphToggle) => {
            const currentBar = series.find(
                (entry: GraphToggle) => entry.name === prevBarsMap[name],
            );

            if (currentBar) {
                return item.name === 'prevDay' && currentBar.selected;
            }

            return false;
        },
    );

    if (isPrev && isSelectedPrevDay) {
        return isSelectedPrevDay.selected;
    }

    if (isSelectedNotPrevDay) {
        return isSelectedNotPrevDay.selected;
    }

    return false;
};

export const getLabelOffset = (
    res: HistoricalResolution,
    type: GraphTypes,
): number => {
    if (type === GraphTypes.GRID) {
        if (res === HistoricalResolution.DAY1HOUR) {
            return -24;
        }

        if (res === HistoricalResolution.WEEK) {
            return 52;
        }

        if (res === HistoricalResolution.YEAR) {
            return -50;
        }

        return 5;
    }

    if (type === GraphTypes.BATTERY) {
        if (res === HistoricalResolution.DAY1HOUR) {
            return -1;
        }

        if (res === HistoricalResolution.WEEK) {
            return 0;
        }

        if (res === HistoricalResolution.YEAR) {
            return 0;
        }

        return 5;
    }

    return 0;
};

export const getMaxValue = (data: any, res: HistoricalResolution): number => {
    if (!data) {
        return 0;
    }

    const values = Object.keys(data)
        .map((key: string) => {
            if (!data[key] || !data[key][res]) {
                return 0;
            }

            return data[key][res].data.map((entry: any) => entry.value);
        })
        .reduce(
            (all: number[], current: number[]): number[] => all.concat(current),
            [],
        );

    return Math.max(...values);
};

export const getMinValue = (data: any, res: HistoricalResolution): number => {
    if (!data) {
        return 0;
    }

    const values = Object.keys(data)
        .map((key: string) => {
            if (!data[key] || !data[key][res]) {
                return 0;
            }

            return data[key][res].data.map((entry: any) => entry.value);
        })
        .reduce(
            (all: number[], current: number[]): number[] => all.concat(current),
            [],
        );

    return Math.min(...values);
};

export function getMetricTotal<B extends boolean>(
    data: any,
    res: HistoricalResolution,
    name?: string,
    powerUnit?: PowerUnit,
    noConversion?: B,
): B extends true ? number : string;

export function getMetricTotal(
    data: any,
    res: HistoricalResolution,
    name: string = 'generation',
    powerUnit: PowerUnit = PowerUnit.Wh,
    noConversion: boolean = false,
) {
    if (!data || !data[name] || !data[name][res]) {
        return noConversion ? 0 : convertPowerValue(0, powerUnit);
    }

    const total = data[name][res].data
        .map(({ value }: { value: number }) => value)
        .reduce((sum: number, entry: number) => sum + (entry || 0), 0);

    return noConversion ? total : convertPowerValue(total, powerUnit);
}

export const getBtProps = (data: any) => {
    const { charging, discharging, state, prevDay } = data;
    const currentData = { charging, discharging, state };

    return { currentData, prevDay };
};

export const getEcProps = (data: any) => {
    const { household, pvbattery, selfsufficiency, prevDay } = data;

    const currentData = {
        household,
        pvbattery,
        selfsufficiency,
    };

    return { currentData, prevDay };
};

export const getOverviewProps = (data: any) => {
    const { prevDay, ...rest } = data;

    return { currentData: rest, prevDay };
};

export const getPvProps = (data: any) => {
    const {
        self,
        saved,
        prevDay,
        exported,
        forecast,
        generation,
        toSolarCloud,
    } = data;

    const currentData = {
        self,
        saved,
        exported,
        forecast,
        generation,
        toSolarCloud,
    };

    return { currentData, prevDay };
};

export const getEmobilityProps = (data: any) => {
    const { emobilityEnergy, prevDay } = data;
    const currentData = { emobilityEnergy };

    return { currentData, prevDay };
};

export const getImportExportProps = (data: any) => {
    const { exported, imported, toSolarCloud, fromSolarCloud, prevDay } = data;
    const currentData = { exported, imported, toSolarCloud, fromSolarCloud };

    return { currentData, prevDay };
};

export const getSmartMeterProps = (data: any) => {
    const { fromGridElectricity, fromGridGas, prevDay } = data;
    const currentData = { fromGridElectricity, fromGridGas };

    return { currentData, prevDay };
};

export const getSmartMeterCostsProps = (data: any) => {
    const { fromGridElectricityCosts, fromGridGasCosts, prevDay } = data;
    const currentData = { fromGridElectricityCosts, fromGridGasCosts };

    return { currentData, prevDay };
};

export const getSolarCloudBalanceProps = (data: any) => {
    const { balance, solarCloudBalanceDelta, prevDay } = data;
    const currentData = { balance, solarCloudBalanceDelta };

    return { currentData, prevDay };
};

export const getMaxSliderValue = (
    res: HistoricalResolution,
    data: any,
    toggles: GraphToggle[],
    type: GraphTypes,
): number => {
    const getProps = {
        [GraphTypes.GRID]: getEcProps,
        [GraphTypes.BATTERY]: getBtProps,
        [GraphTypes.INVERTER]: getPvProps,
        [GraphTypes.EMOBILITY]: getEmobilityProps,
        [GraphTypes.SOLAR_CLOUD]: getImportExportProps,
        [GraphTypes.SMART_METER]: getSmartMeterProps,
        [GraphTypes.IMPORT_EXPORT]: getImportExportProps,
        [GraphTypes.OVERVIEW_POWER]: getOverviewProps,
        [GraphTypes.OVERVIEW_ENERGY]: getOverviewProps,
        [GraphTypes.SMART_METER_COSTS]: getSmartMeterCostsProps,
        [GraphTypes.SOLAR_CLOUD_BALANCE]: getSolarCloudBalanceProps,
    };
    const { currentData, prevDay } = getProps[type](data);

    const lengths = toggles
        .filter((toggle: GraphToggle): boolean => {
            return toggle.name !== 'prevDay' && toggle.selected;
        })
        .map((toggle: GraphToggle): number => {
            if (currentData[toggle.name] && currentData[toggle.name][res]) {
                return currentData[toggle.name][res].data.length;
            }

            return -1;
        });

    const isPrevDaySelected = toggles.find((toggle: GraphToggle) => {
        return toggle.name === 'prevDay' && toggle.selected;
    });

    let prevLengths: any = [];

    if (isPrevDaySelected && prevDay) {
        prevLengths = Object.keys(prevDay).map((item: any) => {
            if (
                prevDay &&
                prevDay[item] &&
                prevDay[item][res] &&
                prevDay[item][res].data
            ) {
                return prevDay[item][res].data.length;
            }

            return -1;
        });
    }

    const max = Math.max(...lengths.concat(prevLengths)) - 1;

    switch (res) {
        case HistoricalResolution.DAY1HOUR:
            return Math.min(23, max);
        case HistoricalResolution.WEEK:
            return Math.min(6, max);
        case HistoricalResolution.YEAR:
            return Math.min(11, max);
        default:
            return Math.min(95, max);
    }
};

export const translateResolutions = (
    resolutions: HistoricalResolution[],
    translation: (value: HistoricalResolution) => string,
) =>
    resolutions.map(
        (value: HistoricalResolution): GraphResolution => ({
            text: translation(value),
            value,
        }),
    );

export const getResolutions = (t: TFunction): GraphResolution[] => {
    const translation = (value: HistoricalResolution): string => {
        switch (value) {
            case HistoricalResolution.DAY15MIN:
                return t('Day (15 min)');
            case HistoricalResolution.DAY1HOUR:
                return t('Day (h)');
            case HistoricalResolution.WEEK:
                return t('Week (d)');
            case HistoricalResolution.MONTH:
                return t('Month (d)');
            case HistoricalResolution.YEAR:
                return t('Year (mon)');
            default:
                return '';
        }
    };

    return translateResolutions(
        Object.values(HistoricalResolution),
        translation,
    );
};

export const getChargingHistoryResolutions = (
    t: TFunction,
): GraphResolution[] => {
    const translation = (value: HistoricalResolution): string => {
        switch (value) {
            case HistoricalResolution.DAY1HOUR:
                return t('Day');
            case HistoricalResolution.MONTH:
                return t('Month');
            case HistoricalResolution.YEAR:
                return t('Year');
            default:
                return '';
        }
    };

    return translateResolutions(
        [
            HistoricalResolution.DAY1HOUR,
            HistoricalResolution.MONTH,
            HistoricalResolution.YEAR,
        ],
        translation,
    );
};

export const excludeResolutions = (
    condition: boolean,
    resolutionsToExclude: HistoricalResolution[],
    t: TFunction,
): GraphResolution[] => {
    const allResolutions = getResolutions(t);

    return condition
        ? allResolutions.filter(
              (res: GraphResolution) =>
                  !resolutionsToExclude.includes(res.value),
          )
        : allResolutions;
};

export const getTotalConsumption = (
    data: any,
    res: HistoricalResolution,
    unit: PowerUnit = PowerUnit.Wh,
): string => {
    if (!data) {
        return '?';
    }

    const { household, emobility } = data;
    const result: number = Object.keys({ household, emobility }).reduce(
        (total: number, current: any): number => {
            const entry = data[current];

            if (!entry || !entry[res] || !entry[res].data) {
                return total;
            }

            const sum = entry[res].data.reduce(
                (a: any, b: any): number => a + b.value,
                0,
            );

            return total + sum;
        },
        0,
    );

    return convertPowerValue(result, unit);
};

export const getResolution = (res: Res): string => {
    switch (res) {
        case HistoricalResolution.DAY1HOUR:
            return HistoricalInterval.ONE_HOUR;

        case HistoricalResolution.WEEK:
        case HistoricalResolution.MONTH:
        case ExtendedHistoricalResolution.ALL:
        case ExtendedHistoricalResolution.THIRTY_DAYS:
            return HistoricalInterval.ONE_DAY;

        case HistoricalResolution.YEAR:
            return HistoricalInterval.ONE_MONTH;

        default:
            return HistoricalInterval.FIFTEEN_MINUTES;
    }
};

export const getComparisonInterval = (
    res: Res,
): HistoricalComparisonInterval | undefined => {
    switch (res) {
        case HistoricalResolution.WEEK:
            return HistoricalComparisonInterval.WEEK;
        case HistoricalResolution.MONTH:
            return HistoricalComparisonInterval.MONTH;
        case HistoricalResolution.YEAR:
            return;
        default:
            return HistoricalComparisonInterval.DAY;
    }
};

export const getModifiedDate = (
    res: Res,
    timestamp: string,
    day: number,
    isPrev: boolean,
): string => {
    if (res === HistoricalResolution.WEEK) {
        return Moment(timestamp.replace(/T\d+/, 'T00'))
            .add(isPrev ? 1 : 0, 'week')
            .format(AGGREGATION_TIME_FORMAT);
    }

    if (res === HistoricalResolution.MONTH) {
        return Moment(timestamp.replace(/T\d+/, 'T00'))
            .add(isPrev ? 1 : 0, 'month')
            .format(AGGREGATION_TIME_FORMAT);
    }

    if (res === HistoricalResolution.YEAR) {
        return Moment(timestamp)
            .add(isPrev ? 1 : 0, 'year')
            .format(AGGREGATION_TIME_FORMAT);
    }

    if (res === ExtendedHistoricalResolution.ALL) {
        return timestamp;
    }

    const [date, time] = timestamp.split('T');
    const [year, month] = date.split('-');
    const mon = Number(month.startsWith('0') ? month.replace('0', '') : month);
    const d = day < 10 ? `0${day}` : day;
    const y = day === 31 && mon === 12 && isPrev ? Number(year) + 1 : year;
    const mNum = day === 1 && isPrev ? mon + 1 : mon;
    const m = mNum < 10 ? `0${mNum}` : mNum > 12 ? '01' : mNum;

    return `${y}-${m}-${d}T${time}`;
};

// prettier-ignore
export const isInvalidValue = (val: any): boolean => val === null || val === undefined || typeof val === 'undefined';

export const getRequestDates = (
    date: Date,
    res: Res,
    fetchPrev: boolean = false,
): Record<string, any> => {
    const d = Moment(date);

    let end = d.clone().endOf('day');
    let start = d
        .clone()
        .startOf('day')
        .subtract(fetchPrev ? 1 : 0, 'day');

    let startingDate = d.clone().startOf('day');
    let resolutionStartDate = d.clone().startOf('day');

    const resolutionEndDate = d.clone().endOf('day');

    if (res === HistoricalResolution.WEEK) {
        start = d
            .clone()
            .startOf('isoWeek')
            .subtract(fetchPrev ? 1 : 0, 'week');

        startingDate = d.clone().startOf('isoWeek').subtract(1, 'week');

        end = d.clone().endOf('isoWeek');
        resolutionStartDate = d.clone().startOf('isoWeek').subtract(1, 'week');
    }

    if (res === HistoricalResolution.MONTH) {
        start = d
            .clone()
            .startOf('month')
            .subtract(fetchPrev ? 1 : 0, 'month');

        end = d.clone().endOf('month');
        startingDate = d.clone().startOf('month').subtract(1, 'month');
        resolutionStartDate = d.clone().startOf('month').subtract(1, 'month');
    }

    if (res === ExtendedHistoricalResolution.THIRTY_DAYS) {
        start = d
            .clone()
            .startOf('day')
            .subtract(fetchPrev ? 62 : 31, 'days');

        end = d.clone().endOf('day');
        resolutionStartDate = d.clone().startOf('day').subtract(30, 'days');
    }

    if (res === HistoricalResolution.YEAR) {
        start = d
            .clone()
            .startOf('year')
            .subtract(fetchPrev ? 1 : 0, 'year');

        end = d.clone().endOf('year');
        startingDate = d.clone().startOf('year').subtract(1, 'year');
        resolutionStartDate = startingDate;
    }

    if (res === ExtendedHistoricalResolution.ALL) {
        start = d
            .clone()
            .startOf('day')
            .subtract(fetchPrev ? 2 : 1, 'year');

        end = d.clone().endOf('day');
        startingDate = d.clone().startOf('day').subtract(1, 'year');
        resolutionStartDate = startingDate;
    }

    return {
        endDate: end.format(AGGREGATION_TIME_FORMAT),
        startDate: start.format(AGGREGATION_TIME_FORMAT),
        startingDate: startingDate.format(AGGREGATION_TIME_FORMAT),
        resolutionEndDate,
        resolutionStartDate,
    };
};

export const mapToLocalTimeZoneDefault = (
    data: UnifiedHistoricalData[],
): UnifiedHistoricalData[] =>
    data.map((item: any) => ({
        ...item,
        timestamp: item.timestamp.replace('Z', '+00:00').replace(/\+.*$/, ''),
        _timestamp: item.timestamp,
    }));

export const mapToLocalTimeZoneDay = (
    data: UnifiedHistoricalData[],
    res: Res,
    firstTimestamp: string,
    timezones: string[],
    length: number,
): UnifiedHistoricalData[] => {
    if (timezones.length <= 1) {
        return mapToLocalTimeZoneDefault(data);
    }

    const is15minutes = res === HistoricalResolution.DAY15MIN;
    const amount = is15minutes ? 15 : 1;
    const period = is15minutes ? 'minutes' : 'hours';

    const result = data.map((item: any, index: number) => ({
        ...item,
        timestamp: Moment(firstTimestamp)
            .add(amount * index, period)
            .toISOString()
            .replace(/\..*$/g, ''),
        _timestamp: item.timestamp,
    }));

    if (result.length > length) {
        result.shift();
    }

    if (result.length < length) {
        const last = result[result.length - 1];

        if (res === HistoricalResolution.DAY15MIN) {
            result.push(
                ...new Array(4)
                    .fill(0)
                    .map((_: number, index: number) => ({
                        ...last,
                        timestamp: Moment(firstTimestamp)
                            .utc()
                            .endOf('day')
                            .add(1, 'millisecond')
                            .subtract(index * 15, 'minutes')
                            .toISOString()
                            .replace(/\..*$/g, ''),
                    }))
                    .reverse(),
            );
        } else {
            result.push({
                ...last,
                timestamp: Moment(firstTimestamp)
                    .utc()
                    .endOf('day')
                    .add(1, 'millisecond')
                    .toISOString()
                    .replace(/\..*$/g, ''),
            });
        }
    }

    return result;
};

export const mapToLocalTimeZoneWeek = (
    data: UnifiedHistoricalData[],
    firstTimestamp: string,
    timezones: string[],
    length: number,
): UnifiedHistoricalData[] => {
    if (timezones.length <= 1) {
        return mapToLocalTimeZoneDefault(data);
    }

    const result = data.map((item: any, index: number) => ({
        ...item,
        timestamp:
            timezones.length > 1
                ? Moment(firstTimestamp)
                      .add(index, 'days')
                      .toISOString()
                      .replace(/\..*$/g, '')
                : item.timestamp.replace('Z', '+00:00').replace(/\+.*$/, ''),
        _timestamp: item.timestamp,
    }));

    if (result.length > length) {
        result.shift();
    }

    return result.map((item: any) => ({
        ...item,
        timestamp: Moment(item.timestamp)
            .add(2, 'hours')
            .startOf('day')
            .format(AGGREGATION_TIME_FORMAT),
    }));
};

export const mapToLocalTimeZone = (
    data: UnifiedHistoricalData[],
    res?: Res,
): UnifiedHistoricalData[] => {
    if (!res) {
        return mapToLocalTimeZoneDefault(data);
    }

    if (data.length === 0) {
        return data;
    }

    let firstTimestamp: string = data[0].timestamp!.toString();

    const length = data.length;
    const offset =
        moment
            ?.tz(firstTimestamp, store?.getState()?.user?.site?.timezone)
            ?.utcOffset() || 0;

    const timezones = data.reduce((zones: string[], item: any) => {
        const timezone = item.timestamp.replace('Z', '+00:00').split('+')[1];

        if (!zones.includes(timezone)) {
            zones.push(timezone);
        }

        return zones;
    }, []);

    firstTimestamp = Moment(firstTimestamp)
        .add(Moment(firstTimestamp).isDST() ? 0 : offset, 'minutes')
        .toISOString();

    if (
        res === HistoricalResolution.WEEK ||
        res === HistoricalResolution.MONTH ||
        res === ExtendedHistoricalResolution.ALL
    ) {
        return mapToLocalTimeZoneWeek(data, firstTimestamp, timezones, length);
    }

    if (res === HistoricalResolution.YEAR) {
        return mapToLocalTimeZoneDefault(data);
    }

    return mapToLocalTimeZoneDay(data, res, firstTimestamp, timezones, length);
};

export const getNextPeriod = (
    amount: number,
    date: Date,
    resolution: HistoricalResolution,
): Date => {
    const today: MomentType = Moment();
    const method = amount < 0 ? 'subtract' : 'add';
    const period =
        resolution === HistoricalResolution.WEEK
            ? 'week'
            : resolution === HistoricalResolution.MONTH
            ? 'month'
            : resolution === HistoricalResolution.YEAR
            ? 'year'
            : 'day';
    const nextDate: MomentType = Moment(date)[method](1, period);

    return nextDate.isAfter(today) ? today.toDate() : nextDate.toDate();
};

export const isToday = (date: Date, res: Res) => {
    if (isNotDayRes(res) || res === ExtendedHistoricalResolution.ALL) {
        return true;
    }

    if (isDayRes(res)) {
        return Moment(date)
            .startOf('day')
            .isSame(Moment(new Date()).startOf('day'));
    }

    return false;
};

export const getLastReadingDate = <T extends Record<string, any>>(
    data: T[],
): string => {
    const validData = data.filter((item: T) => !isInvalidValue(item.value));

    const index = validData.length - 1;

    if (validData.length && typeof validData[index] !== 'undefined') {
        return validData[index].timestamp;
    }

    return data[0]?.timestamp;
};

export const isDayRes = (res: Res) =>
    res === HistoricalResolution.DAY15MIN ||
    res === HistoricalResolution.DAY1HOUR;

export const isNotDayRes = (res: Res) =>
    res === HistoricalResolution.WEEK ||
    res === HistoricalResolution.MONTH ||
    res === HistoricalResolution.YEAR;

export const isSolaxAndNotWeekRes = (
    res: HistoricalResolution,
    provider: InverterProviders,
): boolean =>
    provider === InverterProviders.SolaX && res !== HistoricalResolution.WEEK;

export const isSolaxAndIsWeekRes = (
    res: HistoricalResolution,
    provider: InverterProviders,
): boolean =>
    provider === InverterProviders.SolaX && res === HistoricalResolution.WEEK;

export const isSolarEdgeWithBattery = (
    provider: InverterProviders | '' | undefined,
    hasBattery: boolean,
): boolean => provider === InverterProviders.SolarEdge && hasBattery;

export const normalizeForecastData = (
    forecastData: ForecastValuesResponseModel[],
) =>
    forecastData.map((item: ForecastValuesResponseModel, index: number) => {
        const { values }: any = item;

        if (index === forecastData.length - 1 && values) {
            return {
                ...item,
                values: Object.keys(values).reduce(
                    (result: any, key: string) => {
                        result[key] = 0;

                        return result;
                    },
                    {},
                ),
            };
        }

        return {
            ...item,
            values: {
                ...values,
                PVenergyOutTotal:
                    typeof values.PVenergyOutTotal === 'undefined'
                        ? null
                        : values.PVenergyOutTotal,
            },
        };
    });

export const getSliderValue = (
    res: HistoricalResolution,
    date: Date,
    defaultValue: number,
): number => {
    const currentDate = Moment(date);
    const currentHour = currentDate.get('hours');
    const currentMinutes = currentDate.get('minutes');

    if (res === HistoricalResolution.DAY15MIN) {
        return currentHour * 4 + Math.round(currentMinutes / 15);
    }

    if (res === HistoricalResolution.DAY1HOUR) {
        return currentHour;
    }

    if (
        res === HistoricalResolution.WEEK ||
        res === HistoricalResolution.MONTH ||
        res === HistoricalResolution.YEAR
    ) {
        return 0;
    }

    return defaultValue;
};

export const getFilterTranslation = (
    value: string,
    t: TFunction,
    energyState: Pick<EnergyState, 'provider' | 'hasBattery'>,
    solarCloudStatus?: SolarCloudStatus,
    isOverviewPage = false,
): string => {
    const { provider, hasBattery } = energyState;
    const isSolarEdge = isSolarEdgeWithBattery(provider, hasBattery);

    switch (value) {
        case 'balance':
        case 'solarCloudBalanceDelta':
            return t('Balance');
        case 'batteryPower':
            return t('Battery');
        case 'charging':
        case 'PV2homeToBattery':
            return isOverviewPage ? t('To battery') : t('Charging');
        case 'discharging':
        case 'energyConsumptionFromBattery':
            return isOverviewPage ? t('From battery') : t('Discharging');
        case 'emobility':
        case 'emobilityPower':
        case 'emobilityEnergy':
            return t('Car charging');
        case 'exported':
        case 'PV2homeToGrid':
            switch (solarCloudStatus) {
                case SolarCloudStatus.ACTIVE:
                    return t('To SolarCloud');
                case SolarCloudStatus.MIXED:
                    return t('To SolarCloud / grid');
                default:
                    return isOverviewPage ? t('To grid') : t('Export to grid');
            }
        case 'forecast':
            return t('Forecast');
        case 'fromSolarCloud':
            return t('From SolarCloud');
        case 'generation':
        case 'PV2home':
            return isSolarEdge
                ? t('Generation incl. battery')
                : t('Generation');
        case 'gridPower':
            switch (solarCloudStatus) {
                case SolarCloudStatus.ACTIVE:
                    return t('SolarCloud');
                case SolarCloudStatus.MIXED:
                    return t('SolarCloud / Grid');
                default:
                    return t('Grid');
            }
        case 'household':
        case 'energyConsumption':
            return t('Consumption');
        case 'imported':
        case 'energyConsumptionFromGrid':
            switch (solarCloudStatus) {
                case SolarCloudStatus.ACTIVE:
                    return t('From SolarCloud');
                case SolarCloudStatus.MIXED:
                    return t('From SolarCloud / grid');
                default:
                    return isOverviewPage
                        ? t('From grid')
                        : t('Import from grid');
            }
        case 'fromGridElectricity':
        case 'fromGridElectricityCosts':
            return t('Electricity usage');
        case 'fromGridGas':
        case 'fromGridGasCosts':
            return t('Gas usage');
        case 'inverterPower':
            return t('Inverter');
        case 'nightTariff':
            return t('Night tariff');
        case 'powerConsumption':
            return t('Consumption');
        case 'powerSelfConsumption':
            return t('Self consumption');
        case 'prevDay':
            return t('Previous day');
        case 'prevWeek':
            return t('Previous week');
        case 'prevMonth':
            return t('Previous month');
        case 'prevYear':
            return t('Previous year');
        case 'pvbattery':
            return hasBattery ? t('Generation incl. battery') : t('Generation');
        case 'pvOnly':
            return t('Generation');
        case 'self':
        case 'PV2homeToHome':
        case 'energyConsumptionFromPV':
            return isSolarEdge
                ? t('Self consumption')
                : t('Direct consumption');
        case 'selfsufficiency':
            return t('Self sufficiency');
        case 'state':
            return t('State of charge');
        case 'toSolarCloud':
            return t('To SolarCloud');
        default:
            return '';
    }
};

export const getGraphDateFormat = (
    res: HistoricalResolution,
    defaultFormat: string,
): string => {
    switch (res) {
        case HistoricalResolution.YEAR:
            return 'yyyy';
        case HistoricalResolution.MONTH:
            return 'MMM yyyy';
        default:
            return defaultFormat;
    }
};

export const getGraphSettings = (
    t: TFunction,
    res: HistoricalResolution,
    toggles: GraphToggle[],
    defaultFormat: string,
) => {
    let dateFormat: string = defaultFormat;
    let prevPeriodText: string = t('Previous day');

    if (res === HistoricalResolution.YEAR) {
        dateFormat = getGraphDateFormat(
            HistoricalResolution.YEAR,
            defaultFormat,
        );
        prevPeriodText = t('Previous year');
    }

    if (res === HistoricalResolution.WEEK) {
        prevPeriodText = t('Previous week');
    }

    if (res === HistoricalResolution.MONTH) {
        dateFormat = getGraphDateFormat(
            HistoricalResolution.MONTH,
            defaultFormat,
        );
        prevPeriodText = t('Previous month');
    }

    toggles.map((toggle: GraphToggle) => {
        if (toggle.name === 'prevDay') {
            toggle.text = prevPeriodText;
        }

        return toggle;
    });

    return {
        toggles,
        dateFormat,
    };
};

export const getNormalizedRequestDate = (date: Date): Date => {
    const now = Moment();

    return Moment(date)
        .set({
            hours: now.get('hours'),
            minutes: now.get('minutes'),
            seconds: now.get('seconds'),
            milliseconds: now.get('milliseconds'),
        })
        .toDate();
};

export const prepareInitialDate = (
    date: string,
    timezone: string,
): MomentType => {
    const offset = Moment(date)?.tz(timezone)?.utcOffset() || 0;
    const verb = offset < 0 ? 'subtract' : 'add';

    return Moment(date)[verb](offset, 'minutes');
};

export const removeTimezoneOffset = (date: string): string => date.slice(0, 19);

export const extractAggregatedData = <T extends Record<string, any>>(
    date: Date,
    input: T[],
    res: HistoricalResolution,
    key: keyof T,
    timezone: UserState['site']['timezone'],
    isCurrency: boolean = false,
    comparison?: AggregatedDataValues[],
): AggregatedDataModel => {
    const now = Moment().tz(timezone);
    const isForecast = key === 'PVenergyOutTotal' || key === 'PVpowerOutTotal';

    return {
        [res]: {
            data: input.map((item: T) => {
                const { timeline, timestamp } = item;
                const isAfterNow = Moment(timestamp).isAfter(now);
                // prettier-ignore
                const comparisonItem = comparison?.find((entry: AggregatedDataValues) => entry.timestamp === timestamp);
                const comparisonValueIsNotNull = comparisonItem?.value !== null;

                return {
                    _timestamp: timestamp,
                    timestamp: removeTimezoneOffset(timestamp),
                    value:
                        // If the value is invalid (null, undefined, etc) return `null`
                        isInvalidValue(item[key])
                            ? null
                            : // If it's production, forecast and it's in the past return `null`
                            IS_NOT_DEV && isForecast && comparisonValueIsNotNull
                            ? null
                            : // If it's zero, don't do anything, just return 0
                            item[key] === 0
                            ? 0
                            : // If it's currency, divide by 100 to get the value in the main unit
                            isCurrency
                            ? item[key] / 100
                            : // Otherwise just return the value as it is
                              item[key],
                    isFuture: timeline !== 'currently' ? false : isAfterNow,
                };
            }),
            date: removeTimezoneOffset(Moment(date).tz(timezone).toISOString()),
        },
    };
};

export const getFilterAlias = (name: string): string => {
    switch (name) {
        case 'exported':
            return 'toSolarCloud';
        case 'imported':
            return 'fromSolarCloud';
        default:
            return '';
    }
};

export const shouldShowForecast = (
    selectedRes: HistoricalResolution,
    selectedDate: Date,
    provider: InverterProviders | '',
    hasBattery: boolean,
): boolean => {
    if (!checkForScopes([Scope.PVFORECAST_READ])) {
        return false;
    }

    if (isSolarEdgeWithBattery(provider, hasBattery)) {
        return false;
    }

    if (IS_NOT_DEV) {
        return (
            isToday(selectedDate, selectedRes) &&
            selectedRes === HistoricalResolution.DAY1HOUR
        );
    }

    return (
        selectedRes === HistoricalResolution.DAY1HOUR ||
        selectedRes === HistoricalResolution.WEEK
    );
};

export const getGraphToggles = (
    type: GraphTypes,
    energy: AggregatedDataEnergyState,
    showForecast: boolean,
): string[] => {
    const {
        hasGCP,
        hasBattery,
        hasInverter,
        hasGasMeter,
        hasSmartMeter,
        hasElectricityMeter,
    } = energy;
    switch (type) {
        case GraphTypes.BATTERY:
            return ['charging', 'discharging', 'state'];

        case GraphTypes.EMOBILITY:
            return ['emobilityEnergy'];

        case GraphTypes.GRID:
            return ['household', 'pvbattery', 'selfsufficiency'];

        case GraphTypes.IMPORT_EXPORT:
            return ['exported', 'imported'];

        case GraphTypes.OVERVIEW_ENERGY:
            if (hasSmartMeter && !hasInverter) {
                return ['PV2homeToGrid', 'energyConsumptionFromGrid'];
            }

            if (hasInverter && !hasGCP) {
                return ['generation'];
            }

            if (hasInverter && !hasBattery) {
                return [
                    'generation',
                    'PV2homeToGrid',
                    'PV2homeToHome',
                    'energyConsumption',
                    'energyConsumptionFromGrid',
                    'energyConsumptionFromPV',
                ];
            }

            return [
                'generation',
                'PV2homeToBattery',
                'PV2homeToGrid',
                'PV2homeToHome',
                'energyConsumption',
                'energyConsumptionFromBattery',
                'energyConsumptionFromGrid',
            ];

        case GraphTypes.OVERVIEW_POWER:
            if (hasSmartMeter && !hasInverter) {
                return ['gridPower'];
            }

            if (hasInverter && !hasGCP) {
                return ['inverterPower'];
            }

            if (hasInverter && !hasBattery) {
                return [
                    'inverterPower',
                    'gridPower',
                    'powerConsumption',
                    'powerSelfConsumption',
                ];
            }

            return [
                'inverterPower',
                'gridPower',
                'batteryPower',
                'powerConsumption',
                'powerSelfConsumption',
            ];

        case GraphTypes.INVERTER:
            return hasSmartMeter
                ? ['generation', ...(showForecast ? ['forecast'] : [])]
                : hasGCP
                ? [
                      'generation',
                      'exported',
                      ...(showForecast ? ['forecast'] : []),
                  ]
                : ['generation', ...(showForecast ? ['forecast'] : [])];

        case GraphTypes.SOLAR_CLOUD:
            return ['toSolarCloud', 'fromSolarCloud'];

        case GraphTypes.SOLAR_CLOUD_BALANCE:
            return ['solarCloudBalanceDelta'];

        case GraphTypes.SMART_METER:
            return hasGasMeter && hasElectricityMeter
                ? ['fromGridElectricity', 'fromGridGas']
                : hasGasMeter
                ? ['fromGridGas']
                : ['fromGridElectricity'];

        case GraphTypes.SMART_METER_COSTS:
            return hasGasMeter && hasElectricityMeter
                ? ['fromGridElectricityCosts', 'fromGridGasCosts']
                : hasGasMeter
                ? ['fromGridGasCosts']
                : ['fromGridElectricityCosts'];

        default:
            return [];
    }
};

export const shouldRenderWeather = (
    res: HistoricalResolution,
    date: Date,
): boolean =>
    isDayRes(res) &&
    Moment(date).startOf('day').isSame(Moment(new Date()).startOf('day'));

export const isTodayResWithSmartMeter = (
    hasSmartMeter: boolean,
    res: HistoricalResolution,
    date: Date,
): boolean =>
    hasSmartMeter &&
    !isNotDayRes(res) &&
    Moment(date).startOf('day').isSame(Moment().startOf('day'));

export const shouldRenderForSmartMeter = (
    hasSmartMeter: boolean,
    data: any,
    res: HistoricalResolution,
    isUkSmartMeter: boolean = false,
    graphType: GraphTypes,
): boolean => {
    if (!hasSmartMeter) {
        return true;
    }

    if (!isUkSmartMeter) {
        return smartMeterDataUnavailable(data, res) ? false : true;
    }

    const isCostsGraph = graphType === GraphTypes.SMART_METER_COSTS;
    const gasKey = isCostsGraph ? 'fromGridGasCosts' : 'fromGridGas';
    const electricityKey = isCostsGraph
        ? 'fromGridElectricityCosts'
        : 'fromGridElectricity';

    return smartMeterDataUnavailable(data, res, electricityKey) &&
        smartMeterDataUnavailable(data, res, gasKey)
        ? false
        : true;
};

export const getPreviousPeriodText = <T extends string>(
    name: T,
    res: Res,
): T | 'prevDay' | 'prevWeek' | 'prevMonth' | 'prevYear' =>
    name !== 'prevDay'
        ? name
        : res === HistoricalResolution.WEEK
        ? 'prevWeek'
        : res === HistoricalResolution.MONTH
        ? 'prevMonth'
        : res === HistoricalResolution.YEAR
        ? 'prevYear'
        : 'prevDay';

export const splitRange = (
    start: number,
    end: number,
    parts: number,
): number[] => {
    const result: number[] = [];
    const delta: number = (end - start) / (parts - 1);

    while (start < end) {
        result.push(Math.round(start));
        start += delta;
    }

    result.push(Math.round(end));

    return Array.from(new Set(result.filter((n: number) => n > 0)));
};
